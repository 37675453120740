import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Components/Pages/Home/Home";
import Contact from "./Components/Pages/ContactUs/Contact";
import { Career } from "./Components/Pages/Career/Career";
import { Recepies } from "./Components/Pages/Recepies/Recepies";
import Gallery from "./Components/Pages/Gallery/Gallery";
import Products from "./Components/Pages/Products/Products";
import OtherBrands from "./Components/Pages/OtherBrands/OtherBrands";
import Item from "./Components/Pages/Products/Item";
import Viewcategory from "./Components/Pages/View-Category/view-category";
import Select from "./Components/Pages/Recepies/select";
// import Incredients from "./Components/Pages/Products/incredients";
import React from "react";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/career" element={<Career />} />
          <Route path="/products" element={<Products />}></Route>
          <Route path="/otherbrands" element={<OtherBrands />}></Route>
          <Route path="/item" element={<Item />} />
          <Route path="/recepies" element={<Recepies />} />
          <Route path="/recepies/select" element={<Select />} />
          <Route path="/viewcategory" element={<Viewcategory />} />
          <Route path="/gallery" element={<Gallery />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React,{useEffect,useState} from "react";
import "./Footer.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import footerlogo from "../../Assets/Footer/footerlogo1.svg";
import facebook from "../../Assets/Footer/facebook.svg";
import instagram from "../../Assets/Footer/instagram.svg";
import youtube from "../../Assets/Footer/youtube.svg";
import twitter from "../../Assets/Footer/twitter.svg";

function Footer() {
  const [time,setTime] =useState(new Date())
  useEffect(()=>{
    setInterval(()=>setTime(new Date()),1000)
  },[])
  return (
    <div className="footerdiv" id="footer">
      <Container className="footercontainer">
        <Row>
          <img
            className="footer-logo"
            src={footerlogo}
            href="/#home"
            alt="logo"
          ></img>
        </Row>
        <Row>
          <div className="footersubhead">We make super foods super tasty</div>
        </Row>
        <Row>
          <ul type="none" className="footerlist">
            <li>
              <a href="/#home">Home</a>
            </li>
            <li>
              <a href="/#about">About Us</a>
            </li>
            <li>
              <a href="/viewcategory">Category</a>
            </li>
            <li>
              <a href="/products">Products</a>
            </li>
            <li>
              <a href="/otherbrands">Other Brands</a>
            </li>
            <li>
              <a href="/recepies">Recipes</a>
            </li>
            <li>
              <a href="/gallery">Gallery</a>
            </li>
            <li>
              <a href="/career">Career</a>
            </li>

            <li>
              <a href="/contact">Contact Us</a>
            </li>
          </ul>
        </Row>
        <Row>
          <ul type="none" className="footerlogolist">
            <li>
              <button className="footerbtn">
                <a href="https://www.facebook.com/profile.php?id=100080574760708">
                  <img src={facebook} className="facebook" alt="fb" />
                </a>
              </button>
            </li>

            <li>
              <button className="footerbtn">
                <a href="https://www.instagram.com/firafoods/">
                  <img src={instagram} className="instagram" alt="insta" />
                </a>
              </button>
            </li>
            <li>
              <button className="footerbtn">
                <a href="https://twitter.com/">
                  <img src={twitter} className="twitter" alt="teitter" />
                </a>
              </button>
            </li>
            <li>
              <button className="footerbtn">
                <a href="https://www.youtube.com/@firafoods">
                  <img src={youtube} className="youtube" alt="utube" />
                </a>
              </button>
            </li>
          </ul>
        </Row>
      </Container>
      <div className="copyrightdiv">
        <div>
          <span className="lefttext">
            Designed By
            <a href="https://nthindex.com">
              &nbsp;Nthindex Software Solutions LLP
            </a>
          </span>
        </div>
        <div>
          <span className="righttext">Copyright © 2022-{time.getFullYear()} firafood.com</span>
        </div>
      </div>
    </div>
  );
}

export default Footer;

import React from "react";
import line from "../../../../Assets/About/Line2.svg";
import "./containerFive.css";

function ContainerFive() {
  return (
    <div className="five-art">
    <div className="five-bg">
      <div className="left-top">
      <div className="left-bottom">
      <div className="right-bottom">
        <div className="five-heading">
          <p>We’ll fulfill your food ideas as well</p>
        </div>
        <div className="five-content">
          <p>
            Fira Foods is open to innovative thoughts. We understand the role of
            retailers through whom our products reach the end consumer. They
            interact with consumers on a day-to-day basis and get to know their
            changing tastes and emerging needs. Stockists and distributors also
            have ways to pick up information directly from the market. Our
            strategy is to work closely with these touchpoints. We have a
            dedicated R&D that processes the feedback thus received and sets to
            take it further. In due course, we develop products that meet the
            specifications they recommend. It’s an inclusive approach that has
            helped us make headways even in difficult markets.{" "}
          </p>
          <img src={line} alt="underline" />
        </div>
      </div>
      </div>
      </div>
    </div>
    </div>
  );
}

export default ContainerFive;

import React, { useEffect, useState } from "react";
import "./Banner.css";
import Carousel from "react-bootstrap/Carousel";
import axios from "../../../../network/api";
import { baseUrl } from "../../../../network/constants/Constants";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

function Banner() {
  const [loading, setLoading] = useState("");
  const [banner, setBanner] = useState("");

  useEffect(() => {
    fetchBanner();
  }, []);

  const fetchBanner = () => {
    setLoading(true);

    axios
      .get(baseUrl + "/webapp/banner/")
      .then((response) => {
        setLoading(false);

        setBanner(response.data);
      })
      .catch((error) => {
        console.log(error + "SANDRA");

        setLoading(true);
      });
  };

  return (
    <div id="banner" className="bannerdiv">
      <Carousel touch={true}>
        {banner &&
          banner.map((b, index) => {
            return (
              <Carousel.Item interval={5000}>
                <img
                  className="d-block w-100"
                  src={b.banner}
                  alt="First slide"
                />
              </Carousel.Item>
            );
          })}
      </Carousel>

      {loading && (
        <Box sx={{ display: "center", justifyContent: "center", color: "rgba(45, 157, 75, 1)" }}>
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}

export default Banner;

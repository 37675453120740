import React from 'react'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
import map from "../../../../Assets/Location/map.svg"
import './Location.css'
function Location() {
  return (
    <div className='locationdiv' id='location'>
        <Container>
            <Row lg={12} sm={12} xs={12}>
                <div className='locationhead'>We Span Across India & Middle East</div>
                <div className='locationimg'><img src={map} alt="map" /></div>
            </Row>
        </Container>
    </div>
  )
}

export default Location
import React from 'react';
import { useEffect, useState } from "react";
// import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import './CustomAlert.css'
function CustomAlert(props) {
    const [show, setShow] = useState(false);

  const closeHandler = (e) => {
    setShow(false);
    props.onClose(false);
  };
  const closeHandlerone = (e) => {
    setShow(false);
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);
  return (
    
      
    <div
    style={{
      visibility: show ? "visible" : "hidden",
      opacity: show ? "1" : "0"
    }}
  >

<Dialog
      
       open={show}
      onClose={closeHandlerone}
      aria-labelledby="responsive-dialog-title"
      aria-describedby="alert-dialog-description"
      className="dialog"
    >
      <DialogTitle id="responsive-dialog-title" className="errheadstl">
        {props.title}
      </DialogTitle>
      <DialogContentText id="alert-dialog-description" className="errcon">
        {props.children}
      </DialogContentText>

      <DialogActions className="actoionbtn">
        <Button autoFocus  onClick={closeHandler} className="errdelete">
          Ok
        </Button>
      
      </DialogActions>
    </Dialog>





  </div>

  );
}

export default CustomAlert;

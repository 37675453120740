import React, { useState, useEffect } from "react";
import "./Products.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import circle from "../../../Assets/Ellipse 9.png";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Carousel from "react-grid-carousel";
import Footer from "../../Footer/Footer";
import { ImArrowRight2 } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import Header from "../../Header/header_home";
import axios from "../../../network/api";
import { baseUrl } from "../../../network/constants/Constants";
import { useLocation } from "react-router-dom";

const Products = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [products, setProducts] = useState([]);
  const { state } = useLocation();
  const [category, setCategory] = useState([]);
  const [categorysearch, setCategorySearch] = useState([]);
  const [loading, setLoading] = useState("");

  useEffect(() => {
    fetchCategorySeacrh();
  }, []);

  useEffect(() => {
    fetchCategory();
  }, []);

  useEffect(() => {
    if (categories.length > 0) {
      const id = state && state.id;

      if (id !== null) {
        let index = categories.findIndex((x) => x.id === id);

        if (index !== -1) {
          setSelectedIndex(index);
          fetchProductsByCategory(index);
        } else {
          setSelectedIndex(0);
          fetchProductsByCategory(0);
        }
      } else {
        setSelectedIndex(0);
        fetchProductsByCategory(0);
      }
    }
  }, [categories]);

  // search api

  function callSearchAPI(searchValue) {
    setLoading(true);

    setCategory(filterByValue(searchValue));
  }
  function filterByValue(string) {
    return categorysearch.filter((o) => {
      return Object.keys(o).some((k) => {
        if (typeof o[k] === "string")
          return o[k].toLowerCase().includes(string.toLowerCase());
      });
    });
  }

  function fetchCategory() {
    setLoader(true);
    axios
      .get(baseUrl + "/webapp/category/list/")
      .then((response) => {
        setLoader(false);
        setCategories(response.data);
        console.log(
          "categories::::::::::::::::::::::>>>>>>>>>>>>>>>>>>>>>>>>>>>>> " +
            response.data
        );
        console.log(categories.length);
      })
      .catch((error) => {
        console.log(" catgory List API Error: " + error);
        setLoader(true);
      });
  }

  function fetchProductsByCategory(index) {
    setLoader(true);
    console.log("selected: " + index);
    axios
      .get(baseUrl + "/webapp/product/" + categories[index].id + "/list/")
      .then((response) => {
        setLoader(false);
        setProducts(response.data);
        console.log(
          "caty{}{}{}{}{}{}{}{}{}{}{}{}{}{{}{}{}{{}{}{}{}}} " +
            JSON.stringify(response.data)
        );
      })
      .catch((error) => {
        console.log("error: " + error);
        setLoader(true);
      });
  }

  // category search

  const fetchCategorySeacrh = () => {
    setLoading(true);

    axios
      .get(baseUrl + "/webapp/category/list/")
      .then((response) => {
        setLoading(false);

        setCategory(response.data);
        setCategorySearch(response.data);
        console.log("caty..................." + JSON.stringify(response.data));
      })
      .catch((error) => {

        setLoading(false);
      });
  };

  const handleItemView = (object, products) => {
    navigate("/item", { state: { object: object, products: products } });
  };

  return (
    <>
      <section className="products" id="products">
        <div className="bottom-bg">
          <div className="product-head">
            <Header color="black" />
          </div>
          <div>
            <div className="right-bg">
              <div className="mainproduct-cardsection">
                <Container className="mainproduct-cardsection">
                  <Col className="secrh-filterdiv">
                    <Paper
                      component="form"
                      sx={{
                        display: "flex",

                        backgroundColor: "#ededed",
                        borderRadius: "20px",
                      }}
                    >
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search"
                        inputProps={{ "aria-label": "search" }}
                        onChange={(event) => {
                          callSearchAPI(event.target.value);
                        }}
                      />
                      <IconButton
                        type="button"
                        sx={{ p: "10px" }}
                        aria-label="search"
                      >
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                  </Col>

                  <Row className="mainproduct-rowone">
                    <div>
                      <span>Fira Products</span>
                    </div>
                    <div className="text">The Flavour of India</div>
                    <Carousel
                      touch={true}
                      slide={true}
                      cols={5}
                      rows={1}
                      gap={8}
                      hideArrow={false}
                    >
                      {/* <Carousel.Item> */}
                      {categories &&
                        categories.map((category, index) => {
                          {
                            console.log(
                              "Cate tite: " + categories[index].title
                            );
                          }

                          return (
                            <Carousel.Item>
                              <div className="scroll-item">
                                <div
                                  className="scroll-card"
                                  onClick={() => {
                                    fetchProductsByCategory(index);
                                    setSelectedIndex(index);
                                  }}
                                >
                                  <CardMedia
                                    component="img"
                                    className="selected-image"
                                    height="140"
                                    image={category.image}
                                  ></CardMedia>
                                  <p>{category.title}</p>
                                </div>
                              </div>
                            </Carousel.Item>
                          );
                        })}

                      {/* </Carousel.Item> */}
                    </Carousel>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
          <div>
            <Container className="selectedproduct-cardsection">
              <Row className="selectedproduct-rowone" xs={12} md={12}>
                <div className=" selected-itemhead">
                  {categories &&
                    categories.length > 0 &&
                    categories[selectedIndex].title}
                  <span className="selected-itemsubhead">
                    &nbsp;({products.length}&nbsp;Items)
                  </span>

                  <span className="selected-itemsubhead">{}</span>
                </div>
                {loader && (
                  <Box sx={{ display: "center", justifyContent: "center" }}>
                    <CircularProgress color="success" />
                  </Box>
                )}

                {products &&
                  products.map((object, index) => {
                    return (
                      <Col lg={3} className="selected-item-col">
                        <Card
                          sx={{ maxWidth: 220 }}
                          key={index}
                          className="selected-card"
                          onClick={() => handleItemView(object, products)}
                        >
                          {object.images.length !== 0 ? (
                            <CardMedia
                              component="img"
                              className="category-selected-image"
                              image={
                                baseUrl + "/media/" + object.images[0].image
                              }
                            ></CardMedia>
                          ) : (
                            <div className="text-center">
                              No Image Available
                            </div>
                          )}
                          <CardContent>
                            <Typography
                              gutterBottom
                              className="seleted-title"
                              component="div"
                            >
                              {object.pd_name}
                            </Typography>
                          </CardContent>
                          <CardActions className="selected-cardaction">
                            <Typography className="selected-gram">
                              {object.pd_quantity}
                            </Typography>
                            <span className="gm-view">View&nbsp;</span>
                            <Button
                              className="selected-btn"
                              onClick={() => handleItemView(object, products)}
                            >
                              <ImArrowRight2 className="selected-arrow" />
                            </Button>
                          </CardActions>
                        </Card>
                      </Col>
                    );
                  })}
              </Row>
            </Container>
          </div>
        </div>
        <div className="product-circle">
          <img src={circle} alt="circle" />
        </div>
      </section>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default Products;

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Recepies.css";
import "./RecipeComponent.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { ImArrowRight2 } from "react-icons/im";
import { ImArrowLeft2 } from "react-icons/im";
import modalimg from "../../../Assets/Recepies/modal.svg";
import ingredient from "../../../Assets/Recepies/ingredient.svg";
import typeicon from "../../../Assets/Recepies/typeicon.svg";
import time from "../../../Assets/Recepies/time.svg";
import method from "../../../Assets/Recepies/method.svg";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import { baseUrl } from "../../../network/constants/Constants";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

function RecipeComponent({ recepieObj, recepies }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState("");

  return (
    <div>
      {recepieObj.length === 0 && !loading && (
        <div className="text-center mt-5 mb-5">No Recepies Available</div>
      )}

      {recepieObj && (
        <Card className="recepie-card" onClick={handleShow}>
          <CardMedia
            className="recepie-image"
            image={baseUrl + "/media/" + recepieObj.image}
            alt="green iguana"
          ></CardMedia>
          <CardContent className="recepie-content">
            <Typography gutterBottom className="recepie-type" component="div">
              {recepieObj.type}
            </Typography>
            <Typography gutterBottom className="recepie-title" component="div">
              {recepieObj.title}
            </Typography>
            <Typography gutterBottom className="recepie-method" component="div">
              <img src={method} alt="method" />
              &nbsp;
              {recepieObj.recipe_level}
            </Typography>
            <Typography gutterBottom className="recepie-time" component="div">
              <img src={time} alt="time" />
              &nbsp;
              {recepieObj.time}
            </Typography>{" "}
            <Typography
              gutterBottom
              className="recepie-subtype"
              component="div"
            >
              <img src={typeicon} alt="icon" />
              &nbsp;
              {recepieObj.type}
            </Typography>
            <Typography
              gutterBottom
              className="recepie-ingredient"
              component="div"
            >
              <img src={ingredient} alt="ingredient" />
              &nbsp;
              {recepieObj.ingrediants.length === 1
                ? recepieObj.ingrediants.length + " Ingredient"
                : recepieObj.ingrediants.length + " Ingredients"}
            </Typography>
            <Typography gutterBottom className="recepie-desc" component="div">
              {recepieObj.description}
            </Typography>
          </CardContent>
          <CardActions className="selected-cardaction">
            <Button className="recepie-btn" onClick={handleShow}>
              <ImArrowRight2 className="recepie-arrow" />
            </Button>
          </CardActions>
        </Card>
      )}
      {loading && (
        <Box sx={{ display: "center", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body
          style={{
            maxHeight: "calc(100vh - 210px)",
            overflowY: "auto",
          }}
        >
          <Container className="modal-container">
            <Row>
              <Col lg={4} className="modal-colone">
                <Row className="modal-coloneinnerrowone">
                  <img
                    src={baseUrl + "/media/" + recepieObj.image}
                    className="modalpic"
                    alt="modal"
                  />

                  <span>Ingredient</span>
                </Row>
                {recepieObj.ingrediants.map((ing) => {
                  return (
                    <Row className="modal-coloneinnerrowthree">
                      <Col>
                        <ul type="none" className="modal-items">
                          <li>{ing.ingrediants}</li>
                        </ul>
                      </Col>
                      <Col>
                        <ul type="none" className="modal-qty">
                          <li>{ing.measurement}</li>
                        </ul>
                      </Col>
                    </Row>
                  );
                })}
              </Col>
              <Col lg={8} className="modal-coltwo">
                <div className="modal-btnsection">
                  <img src={modalimg} alt="modal"/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Button onClick={handleClose} className="modal-btn">
                    <ImArrowLeft2 />
                  </Button>
                </div>
                <Typography gutterBottom className="modal-type" component="div">
                  {recepieObj.type}
                </Typography>
                <Typography
                  gutterBottom
                  className="modal-title"
                  component="div"
                >
                  {recepieObj.title}
                </Typography>

                <div className="modal-list">
                  <Typography
                    gutterBottom
                    className="modal-method"
                    component="div"
                  >
                    <img src={method} alt="method" />
                    &nbsp;
                    {recepieObj.recipe_level}
                  </Typography>
                  <Typography
                    gutterBottom
                    className="modal-time"
                    component="div"
                  >
                    <img src={time} alt="time" />
                    &nbsp;
                    {recepieObj.time}
                  </Typography>{" "}
                  <Typography
                    gutterBottom
                    className="modal-subtype"
                    component="div"
                  >
                    <img src={typeicon} alt="type" />
                    &nbsp;
                    {recepieObj.type}
                  </Typography>
                  <Typography
                    gutterBottom
                    className="modal-ingredient"
                    component="div"
                  >
                    <img src={ingredient} alt="ingredient" />
                    &nbsp;
                    {recepieObj.ingrediants.length === 1
                      ? recepieObj.ingrediants.length + " Ingredient"
                      : recepieObj.ingrediants.length + " Ingredients"}
                  </Typography>
                </div>

                <div className="modal-steps">
                  <Typography>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: recepieObj.preparation,
                      }}
                    />
                  </Typography>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>

    </div>
  );
}

export default RecipeComponent;

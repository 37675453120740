import "./Lulu.css";
import LuluLogo from "../../../../Assets/Lulu/lulu_logo.png"
import logo from "../../../../Assets/fira_logo_green.svg"
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.min.css";

function Lulu(){
    return <div className="lulu-container">
        <Container>
            <div className="lulu-content">
                <img src={logo} className="lulu-img"/>
                <div className="available-at">
                    Our products now available at all
                </div>
                <img src={LuluLogo} className="lulu-img"/>
                <div className="hyper-market">HYPERMARKETS</div>
                <div className="middle-east">Across the Middle East & India</div>
            </div>
        </Container>
    </div>;
}

export default Lulu;
import React, { useState, useEffect } from "react";
import "./Career.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../../Header/header_home";
import Footer from "../../Footer/Footer";
import { ImArrowRight2 } from "react-icons/im";
import { BsUpload } from "react-icons/bs";
import Alert from "@mui/material/Alert";
import CustomAlert from "../CustomAlert/CustomAlert";
import FormData from "form-data";
import axios from "../../../network/api";
import { baseUrl } from "../../../network/constants/Constants";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export function Career(props) {
  const [careername, setCareerName] = useState("");
  const [careerphone, setCareerPhone] = useState("");
  const [careermail, setCareerMail] = useState("");
  const [careerdob, setCareerDob] = useState("");
  const [careerjob, setCareerJob] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [careererror, setCareerError] = useState("");
  const [loading, setLoading] = useState("");
  const [vaccancies, setVaccancies] = useState([]);
  const [visibility, setVisibility] = React.useState(false);

  const popupCloseHandler = (e) => {
    setVisibility(e);
  };

  function handleSubmitCareer(e) {
    var filter = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const MIN_AGE = 10;
    const dob = new Date(careerdob);
    const ageInMs = Date.now() - dob.getTime();
    const ageInYears = ageInMs / (1000 * 60 * 60 * 24 * 365);
    function handleSubmitCareer(e) {
      var filter = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
      const MIN_AGE = 10;
      const dob = new Date(careerdob);
      const ageInMs = Date.now() - dob.getTime();
      const ageInYears = ageInMs / (1000 * 60 * 60 * 24 * 365);
  
      if (!careername.trim()) {
        setCareerError("Please enter your name!");
      } else if (careerphone.length !== 10) {
        setCareerError("Please enter your phone number!");
      } else if (!filter.test(careermail.toLowerCase())) {
        setCareerError("Please enter a valid mail address!");
      } else if (!careerdob) {
        setCareerError("Please enter date of birth!");
      } else if (ageInYears < MIN_AGE) {
        setCareerError.careerdob = "You should be greater than 18 years of age";
      } else if (!careerjob) {
        setCareerError("Please select Job!");
      } else if (selectedFile.length === 0) {
        setCareerError("Please upload cv!");
      } else {
        const formData = new FormData();
        formData.append("cv", selectedFile);
        formData.append("name", careername);
        formData.append("email", careermail);
        formData.append("phone_number", careerphone);
        formData.append("date_of_birth", careerdob);
        formData.append("job", careerjob);
  
        console.log("formData name: " + formData.get("name"));
        console.log("formData email: " + formData.get("email"));
        console.log("formData phone_number: " + formData.get("phone_number"));
        console.log("formData date_of_birth: " + formData.get("date_of_birth"));
        console.log("formData cv: " + formData.get("cv"));
  
        axios
          .post(baseUrl + "/webapp/application-form/create/", formData)
          .then((response) => {
            console.log("submited  " + response.data);
            setVisibility(true);
          })
          .catch((error) => {
            console.log("errrrrrrrr " + error);
            setLoading(false);
          });
  
        // setCareerName("");
        // setCareerPhone("");
        // setCareerMail("");
        // setCareerDob("");
        // setCareerJob("");
        // setSelectedFile("");
      }
    }
    if (!careername.trim()) {
      setCareerError("Please enter your name!");
    } else if (careerphone.length !== 10) {
      setCareerError("Please enter your phone number!");
    } else if (!filter.test(careermail.toLowerCase())) {
      setCareerError("Please enter a valid mail address!");
    } else if (!careerdob) {
      setCareerError("Please enter date of birth!");
    } else if (ageInYears < MIN_AGE) {
      setCareerError.careerdob = "You should be greater than 18 years of age";
    } else if (!careerjob) {
      setCareerError("Please select Job!");
    } else if (selectedFile.length === 0) {
      setCareerError("Please upload cv!");
    } else {
      const formData = new FormData();
      formData.append("cv", selectedFile);
      formData.append("name", careername);
      formData.append("email", careermail);
      formData.append("phone_number", careerphone);
      formData.append("date_of_birth", careerdob);
      formData.append("job", careerjob);

      console.log("formData name: " + formData.get("name"));
      console.log("formData email: " + formData.get("email"));
      console.log("formData phone_number: " + formData.get("phone_number"));
      console.log("formData date_of_birth: " + formData.get("date_of_birth"));
      console.log("formData cv: " + formData.get("cv"));

      axios
        .post(baseUrl + "/webapp/application-form/create/", formData)
        .then((response) => {
          console.log("submited  " + response.data);
          setVisibility(true);
        })
        .catch((error) => {
          console.log("errrrrrrrr " + error);
          setLoading(false);
        });

      // setCareerName("");
      // setCareerPhone("");
      // setCareerMail("");
      // setCareerDob("");
      // setCareerJob("");
      // setSelectedFile("");
    }
  }
  useEffect(() => {
    fetchVaccancies();
  }, []);

  const fetchVaccancies = () => {
    setLoading(true);
    axios
      .get(baseUrl + "/webapp/vaccancies/list/")
      .then((response) => {
        setLoading(false);

        setVaccancies(response.data);
      })
      .catch((error) => {
        console.log(error + "SANDRA");

        setLoading(true);
      });
  };

  return (
    <section id="career">
      <div className="career" id="career">
        <div className="career-header">
          <Header />
        </div>
        <div></div>
        <div className="careercontainer">
          <Container>
            <Row>
              <Col sm={6} xs={12} lg={6} className="career-colone">
                <Row md={12} sm={12}>
                  <span className="careercard-head">Available Vacancies</span>
                  <p className="careercard-subhead">
                    Join And Grow Old With Us
                  </p>
                </Row>

                {vaccancies &&
                  vaccancies.map((v, index) => {
                    return (
                      <Row sm={12} md={12} lg={12}>
                        <div className="career-card">
                          <span className="career-cardhead">{v.title}</span>
                          <Row className="careercard-content">
                            <Row className="careercard-contentcol">
                              <Col xs={12} lg={3}>
                                {" "}
                                <p className="careercard-left">Qualification</p>
                              </Col>
                              <Col xs={12} lg={9}>
                                <p className="careercard-right">
                                  {v.qualification}
                                </p>
                              </Col>
                            </Row>
                            <Row className="careercard-contentcol">
                              <Col xs={12} lg={3}>
                                {" "}
                                <p className="careercard-left">Location</p>
                              </Col>
                              <Col xs={12} lg={9}>
                                <p className="careercard-right">{v.location}</p>
                              </Col>
                            </Row>
                            <Row className="careercard-contentcol">
                              <Col xs={12} lg={3}>
                                {" "}
                                <p className="careercard-left">Last Date</p>
                              </Col>
                              <Col xs={12} lg={9}>
                                <p className="careercard-right">
                                  {v.last_date}
                                </p>
                              </Col>
                            </Row>
                          </Row>
                        </div>
                      </Row>
                    );
                  })}
              </Col>

              <Col sm={6} lg={6} xs={12} className="career-coltwo">
                <div className="applydiv">
                  <span>Apply With Us</span>
                  <div className="apply-formsection">
                    <label className="career_label">Name</label>
                    <br></br>
                    <div className="textfield-div">
                      <input
                        type="text"
                        placeholder="Enter name"
                        value={careername}
                        className="input-field"
                        onChange={(e) => setCareerName(e.target.value)}
                      ></input>
                    </div>
                    <br></br>
                    <label className="career_label">Phone Number</label>
                    <br></br>
                    <div className="textfield-div">
                      <input
                        type="tel"
                        className="input-field"
                        placeholder="Enter Number"
                        value={careerphone}
                        onChange={(e) => setCareerPhone(e.target.value)}
                      ></input>
                    </div>
                    <br></br>
                    <label className="career_label">Mail ID</label>
                    <br></br>
                    <div className="textfield-div">
                      <input
                        type="email"
                        className="input-field"
                        placeholder="Enter Mail"
                        value={careermail}
                        onChange={(e) => setCareerMail(e.target.value)}
                      ></input>
                    </div>
                    <br></br>
                    <label className="career_label">Date of Birth</label>
                    <br></br>
                    <div className="textfield-div">
                      <input
                        type="date"
                        // max="2013-01-01"
                        className="input-field"
                        placeholder="Search"
                        value={careerdob}
                        onChange={(e) => setCareerDob(e.target.value)}
                        // isInvalid={!!errors.dob}
                      ></input>
                      {/* <p>{errors.dob}</p> */}
                    </div>
                    <br></br>
                    <label className="career_label">Job</label>
                    <br></br>
                    <div className="textfield-div">
                      <select
                        id="cars"
                        name="cars"
                        className="input-field careerselect"
                        placeholder="Select"
                        value={careerjob}
                        onChange={(e) => setCareerJob(e.target.value)}
                      >
                        <option value="Select Query">Select Job</option>
                        {vaccancies.map((item) => {
                          {
                            return (
                              <option value={item.title}>{item.title}</option>
                            );
                          }
                        })}
                      </select>
                    </div>
                    <br></br>
                    <label className="career_label">Upload CV</label>
                    <br></br>
                    <div className="textfield-div">
                      <input
                        id="file"
                        type="file"
                        onChange={(e) => setSelectedFile(e.target.files[0])}
                        className="input-field files "
                      ></input>
                      <BsUpload className="upload-icon" />
                    </div>
                    <br></br>
                    <div className="careererror">
                      {careererror && (
                        <Alert
                          variant="standard"
                          size="small"
                          severity="error"
                          className="careeralert"
                        >
                          {careererror}
                        </Alert>
                      )}
                      <button
                        type="submit"
                        className="career-btn"
                        onClick={handleSubmitCareer}
                      >
                        Apply&nbsp;
                        <ImArrowRight2 size={12} />
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {loading && (
          <Box sx={{ display: "center", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        )}
        <CustomAlert onClose={popupCloseHandler} show={visibility}>
          <h5>Application Submitted</h5>
        </CustomAlert>
        <div>
          <Footer />
        </div>
      </div>
    </section>
  );
}

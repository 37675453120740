import React, { useState, useEffect } from "react";
import "./Recepies.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import pot from "../../../Assets/Recepies/pot.png";
import Footer from "../../Footer/Footer";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Header from "../../Header/header_home";
import axios from "../../../network/api";
import { baseUrl } from "../../../network/constants/Constants";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import RecipeComponent from "./RecipeComponent";

export const Recepies = () => {
  const [recepies, setRecepies] = useState([]);
  const [originalRecepies, setOriginalRecepies] = useState([]);

  const [loading, setLoading] = useState("");

  useEffect(() => {
    fetchRecipes();
  }, []);

  function callSearchAPI(searchValue) {
    setLoading(true);

    setRecepies(filterByValue(searchValue));
  }

  function filterByValue(string) {
    return originalRecepies.filter((o) => {
      return Object.keys(o).some((k) => {
        if (typeof o[k] === "string")
          return o[k].toLowerCase().includes(string.toLowerCase());
      });
    });
  }

  // recepies search

  const fetchRecipes = () => {
    setLoading(true);

    axios
      .get(baseUrl + "/webapp/recipes/list-complete/")
      .then((response) => {
        setLoading(false);

        setRecepies(response.data);
        setOriginalRecepies(response.data);
      })
      .catch((error) => {
        console.log(error + "SANDRA");

        setLoading(false);
      });
  };

  return (
    <section id="recepies">
      <div className="recepies-section" id="recepies">
        <div className="recepie-main">
          <div className="recepie-header">
            <Header color="black" />
          </div>
          <Container className="recepies-container">
            <Row>
              <Col lg={5} sm={12} className="recepies-colone">
                <div>
                  <span>
                    Super Recipes for Our<br></br> Super Food{" "}
                  </span>
                  <p>
                    Fira products are inspired by the long relished culinary
                    traditions of ethnic communities. We offer an eclectic range
                    comprising authentic recipes and contemporary spins that
                    excite Indian and international food lovers.
                  </p>
                </div>
              </Col>
              <Col lg={7} sm={12} className="recepies-coltwo">
                <img src={pot} alt="pot" />
              </Col>
            </Row>
          </Container>
        </div>
        <Container className="recepie-containertwo">
          <Row className="recepie-containeronerow">
            <Col>
              <span>Recipes</span>
              <p>The Flavour of India</p>
            </Col>
            <Col className="secrh-filterdiv">
              <Paper
                component="form"
                sx={{
                  display: "flex",
                  backgroundColor: "#ededed",
                  borderRadius: "20px",
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search"
                  inputProps={{ "aria-label": "search" }}
                  onChange={(event) => {
                    callSearchAPI(event.target.value);
                  }}
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Col>
          </Row>
        </Container>

        <div>
          {recepies.length === 0 && (
            <div className="text-center mt-5 mb-5">No Recepies Available</div>
          )}

          {/* {loading && (
            <Box sx={{ display: "center", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          )} */}
        </div>
      </div>

      <Container>
        <Row className="recepie-containertworow">
          {recepies &&
            recepies.map((recepiesObj) => {
              {
                return (
                  <Col lg={4} sm={12} className="recepies-colone">
                    <RecipeComponent
                      recepieObj={recepiesObj}
                      recepies={recepies}
                    />
                  </Col>
                );
              }
            })}
        </Row>
      </Container>
      <div>
        <Footer />
      </div>
    </section>
  );
};

import React from "react";
import Banner from "./Banner/Banner";
import About from "./About/About";
import Lulu from "./Lulu/Lulu";
// import HomeCategory from "./Category/HomeCategory";
import Footer from "../../Footer/Footer";
import Location from "./Location/Location";
import "./Home.css";
import Headerhome from "../../Header/header_home";
import HomeCategoryPage from "./Category/HomeCategory/HomeCatogoryPage";

function Home() {
  return (
    <section className="home" id="home">
      
      <div className="banner">
      <Headerhome />
        <Banner />
      </div>
      <div className="about" id="about">
        <About />
      </div>
      <div >
        <HomeCategoryPage/>
      </div>
      <div>
        <Location />
      </div>
      <div>
        <Lulu/>
      </div>
      <div>
        <Footer />
      </div>
    </section>
  );
}

export default Home;
